// Import the functions you need from the SDKs you need

import { initializeApp } from "firebase/app";

import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";

// TODO: Add SDKs for Firebase products that you want to use

// https://firebase.google.com/docs/web/setup#available-libraries


// Your web app's Firebase configuration

// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const firebaseConfig = {

  apiKey: "AIzaSyCo2ESshWMHVWLQZ-Q1YAnUxI7H3DGK1PQ",

  authDomain: "starboat-website.firebaseapp.com",

  projectId: "starboat-website",

  storageBucket: "starboat-website.appspot.com",

  messagingSenderId: "538602499454",

  appId: "1:538602499454:web:ef3a443231622b9088972f",

  measurementId: "G-6LL3NKTCZL"

};


// Initialize Firebase

const app = initializeApp(firebaseConfig);

const analytics = getAnalytics(app);
export const db = getFirestore(app);