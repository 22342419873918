import logo from './logo.svg';
import './App.css';
import ContactForm from "./contact.js";
import Footer from "./footer.js";
import {Box, Container, Typography} from "@mui/material";
// Import your SVG image
import starboatTitle from './img/STARBOAT.png';
import bg from './img/bg.png';
import glow from './img/glow.png';

function App() {
  return (
    <div className="App">
        <Box
            sx={{
                backgroundImage: `url(${bg})`, // Set the background image
                backgroundSize: 'cover', // Make sure the background image covers the entire box
                backgroundPosition: 'center', // Center the background image
                width: '100vw', // Set width to fill the entire viewport width
                height: '95vh', // Set height to fill the entire viewport height
                justifyContent: 'center',
                pt:'5vh',
            }}
        >
          <Box
              sx={{
                  backgroundImage: `url(${starboatTitle})`, // Set the background image
                  backgroundSize: 'contain', // Make sure the background image covers the entire container
                  backgroundRepeat: 'no-repeat',
                  width: '95%',
                  height: '150px', // Adjust the height as needed
                  alignItems: 'center',
                  margin:'auto',
                  paddingTop:'100px',
              }}
          >
          </Box>
          <Box sx={{margin:'10px', position:'absolute', bottom:'170px', left: '50%',
                transform: 'translateX(-50%)', // Center horizontally
                textAlign: 'center',
                width:'500px',
                }}>

          <ContactForm></ContactForm>
          </Box>
        <Footer></Footer>
      </Box>

    </div>
  );
}

export default App;
