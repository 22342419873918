import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { IconButton } from '@mui/material';
import instagram from './img/instagram.png';
import tiktok from './img/tiktok.png';
import linkedin from './img/linkedin.png';

const Footer = () => {
    return (
        <Box
            sx={{
                position: 'fixed',
                bottom: 0,
                left: 0,
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '10px',
            }}
        >

            <Box>
            <a href="https://www.instagram.com/starboat.app/" target="_blank" rel="noopener noreferrer">
                    <IconButton>
                        <img src={instagram} alt="Button 1" style={{ width: '40px', height: '40px' }} />
                    </IconButton>
                </a>
                
                {/* Repeat the same for the other buttons */}
                <a href="https://www.tiktok.com/@starboat.app" target="_blank" rel="noopener noreferrer">
                    <IconButton>
                        <img src={tiktok} alt="Button 2" style={{ width: '40px', height: '40px' }} />
                    </IconButton>
                </a>
                
                <a href="https://www.linkedin.com/company/93092598/" target="_blank" rel="noopener noreferrer">
                    <IconButton>
                        <img src={linkedin} alt="Button 3" style={{ width: '40px', height: '40px' }} />
                    </IconButton>
                </a>
            </Box>
            <Typography variant="body2" sx={{ marginRight: '50px', color:'#a9adff' }}>
                Starboat 2024 - all rights reserved
            </Typography>
        </Box>
    );
};

export default Footer;
