import React, { useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import {Typography} from '@mui/material';
import { Container } from '@mui/material';
import { addDoc, collection } from 'firebase/firestore';
import {db} from "./firebase.js";

const ContactForm = () => {
    const [inputValue, setInputValue] = useState('');
    const [status, setStatus] = useState('pending'); // Initial state is 'pending'

    const handleClick = async (e) => {
        e.preventDefault();  

        // Update the status to 'sent'
        setStatus('sent');

        try {
            const docRef = await addDoc(collection(db, "emails"), {
              email: inputValue,    
            });
            console.log("Document written with ID: ", docRef.id);
          } catch (e) {
            console.error("Error adding document: ", e);
          }
    }

    const handleChange = (e) => {
        setInputValue(e.target.value);
    };

    return (
        <div>
            {status === 'pending' && ( // Render input and button only if status is 'pending'
                <div>
                    <Typography sx={{ color: '#a9adff', marginBottom:'5px'}}>
                        Get notified for product launches
                    </Typography>
                    <TextField
                        label="Email Address"
                        variant="outlined"
                        size="small"
                        value={inputValue}
                        onChange={handleChange}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    borderColor: '#a9adff', // Change border color
                                },
                                '&:hover fieldset': {
                                    borderColor: '#a9adff', // Change border color on hover
                                },
                                '&.Mui-focused fieldset': {
                                    borderColor: '#a9adff', // Change border color when focused
                                },
                            },
                            '& .MuiInputLabel-root': {
                                color: '#a9adff', // Change label color
                            },
                            '& .MuiInputBase-root': {
                                color: '#a9adff', // Change text color
                            },
                        }}
                    />
                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={handleClick}
                        style={{ marginLeft: '10px',color: '#a9adff', borderColor: '#a9adff', height:'40px' }}
                    >
                        Notify me
                    </Button>
                </div>
            )}
            {status === 'sent' && ( // Render a message when status is 'sent'
                <Container>
                  <Typography variant="h7" sx={{ color: '#a9adff'}}>
                     Thank you for subscribing to the Starboat!
                  </Typography>
                </Container>
            )}
        </div>
    );
};

export default ContactForm;
